<template>
  <div class="pa-5">
    <v-row dense>
      <v-col cols="12">
        <v-icon class="mr-2">mdi-account-hard-hat</v-icon>
        <span class="titulo">Pesquisar serviços</span>
      </v-col>
    </v-row>

    <!-- FORMULÁRIO DE PESQUISA -->
    <v-form v-model="valid" ref="form" class="mt-20">
      <v-row dense>
        <v-col cols="12" md="2">
          <v-text-field
            solo dense autofocus
            ref="proposta"
            label="Num.Proposta"
            v-model="data.numProp"
            v-mask="'####-##'"
            prepend-inner-icon="mdi-numeric"
            @keypress.enter.prevent="pesquisar()" />
        </v-col>
        <v-col v-if="usuario.Tipo == 'A'" cols="12" md="8">
          <v-text-field
            solo dense
            label="Empresa"
            v-model="data.empresa"
            prepend-inner-icon="mdi-domain"
            @keypress.enter.prevent="pesquisar()" />
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            solo dense
            label="Status"
            v-model="data.finalizado"
            :items="func.tblStatus"
            item-value="Id"
            item-text="Nome"
            @change="pesquisar()" />
        </v-col>

        <v-col cols="12" class="text-center mt-3">
          <v-btn dark color="success" class="mx-1 mt-2" @click="pesquisar()">
            <v-icon class="mr-2">mdi-magnify</v-icon>
            PESQUISAR
          </v-btn>
          <v-btn dark color="error" class="mx-1 mt-2" @click="limpaCampos()">
            <v-icon class="mr-2">mdi-close</v-icon>
            LIMPAR
          </v-btn>
          <v-btn v-if="usuario.Tipo == 'A'" dark color="blue darken-2" class="mx-1 mt-2" @click="novoRegistro()">
            <v-icon class="mr-2">mdi-plus-thick</v-icon>
            NOVO SERVIÇO
          </v-btn>
        </v-col>

        <v-col cols="12" class="text-center mt-3">
          <div class="mb-2 d-flex flex-column align-end">
            <b class="grey--text text--darken-2">
              <span class="blue--text text--darken-2">{{ itensPesq.length }}</span>
              serviço(s) encontrado(s)
            </b>
          </div>
          <v-data-table
            class="elevation-1 servicos"
            :headers="headers"
            :items="itensPesq"
            :page.sync="pages"
            :items-per-page="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :loading="loading"
            :expanded.sync="expanded"
            item-key="Id"
            hide-default-footer
            @page-count="pageCount = $event">
            <template slot="no-data">
              <v-alert dark color="warning" icon="mdi-exclamation-thick" border="left" class="mt-3">
                REGISTROS NÃO ENCONTRADOS
              </v-alert>
            </template>
            <v-progress-linear slot="progress" color="red" indeterminate></v-progress-linear>
            <template v-slot:item="{ item }">
              <tr :title="usuario.Tipo == 'A' ? '' : 'Clique para visualizar'"
                @click="usuario.Tipo == 'A' ? expandRow(item) : editar(item)">
                <td v-if="usuario.Tipo == 'A'" align="left">{{ item.Empresa }}</td>
                <td align="center">{{ item.NumProposta }}</td>
                <td align="left">{{ item.Descricao }}</td>
                <td align="center">{{ item.Identificacao }}</td>
                <td align="right">
                  <span v-if="func.empty(item.OrdCompra)" class="st-na">
                    AGUARDANDO
                  </span>
                  <span v-else>{{ item.OrdCompra }}</span>
                </td>
                <td align="left">
                  <span :class="(item.Nota == 'NA' ? 'st-na' : (item.Nota == 'POSSUI' ? 'st-possui' : 'st-nao-aplica'))">
                    {{ func.statusNota.filter(n => n.Id == item.Nota)[0].Nome.toLocaleUpperCase() }}
                  </span>
                </td>
                <td align="center">
                  <span :class="func.diffDataDays(item.DtEntregaBR) < 0 ? 'st-na' : ''">
                    <b>{{ item.DtEntregaBR }}</b>
                  </span>
                </td>
                <td class="d-flex align-center justify-end"
                  @mouseover="mouseOnSwitch = true"
                  @mouseout="mouseOnSwitch = false">
                  <v-switch v-if="usuario.Tipo == 'A'"
                    dense inset
                    class="mx-0 px-0"
                    v-model="item.Finalizado"
                    :true-value="1"
                    :false-value="0"
                    @click="trocaStatus(item)">
                  </v-switch>
                  <div v-else>
                    <v-icon v-if="item.Finalizado == 1" color="green">mdi-check-bold</v-icon>
                    <v-icon v-else color="red">mdi-close-thick</v-icon>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length" class="text-center">
                <v-card flat style="border-bottom: solid 1px #ddd">
                  <v-card-text>
                    <v-btn rounded x-small dark class="mx-1" color="blue darken-2"
                      @click="editar(item)">
                      <v-icon small class="mr-2">mdi-pencil</v-icon>
                      Editar
                    </v-btn>
                    <v-btn rounded x-small dark class="mx-1" color="red"
                      @click="excluir(item)">
                      <v-icon small class="mr-2">mdi-trash-can</v-icon>
                      Excluir
                    </v-btn>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>

          <v-pagination class="mt-5" v-model="pages" :length="pageCount"></v-pagination>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="dlgServico" persistent max-width="900px">
      <v-card>
        <v-card-text>
          <form-servico
            :id-servico="idServico"
            @create="pesquisar()"
            @update="pesquisar()">
          </form-servico>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
 
<script>
import func from "../../utils/funcoes";
import { mask } from "vue-the-mask";
import { eventHub } from "../../utils/eventHub";

import form_servico from "../../components/servicos/CadastroWidget.vue";

export default {
  components: {
    "form-servico": form_servico
  },
  directives: { mask },
  data () {
    return {
      func,
      valid: false,
      acao: "ins",
      data: {},

      cliques: 0,

      dlgServico: false,
      idServico: null,

      mouseOnSwitch: false,

      itensPesq: [],
      expanded: [],
      loading: false,
      pages: 1,
      pageCount: 0,
      itemsPerPage: 20,
      sortBy: null,
      sortDesc: null,
      headers: [
        {
          text: "EMPRESA",
          align: "left",
          width: 250,
          sortable: true,
          value: "Empresa",
        },
        {
          text: "PROPOSTA",
          align: "center",
          sortable: false,
          value: "NumProposta",
        },
        {
          text: "DESCRIÇÃO",
          align: "left",
          sortable: false,
          value: "Descricao",
        },
        {
          text: "IDENT.",
          align: "left",
          width: 85,
          sortable: false,
          value: "Identificacao",
        },
        {
          text: "ORD.COMPRA",
          align: "left",
          sortable: false,
          value: "OrdCompra",
        },
        {
          text: "NOTA ENTRADA",
          align: "left",
          width: 145,
          sortable: false,
          value: "Identificacao",
        },
        {
          text: "PREV.ENTREGA",
          align: "left",
          width: 140,
          sortable: true,
          value: "DtEntrega",
        },
        {
          text: "FINALIZADO",
          align: "right",
          sortable: false,
          value: "Finalizado",
        },
      ],
    };
  },
  created () {
    this.data.finalizado = null;
  },
  async mounted () {
    if (this.usuario.Tipo == "U") this.headers.shift();

    this.pesquisar();

    eventHub.$off("form-servico");
    eventHub.$on("form-servico", (data) => {
      if (data == false) this.idServico = null;
      this.dlgServico = data;
    });

    eventHub.$off("excluir-servico");
    eventHub.$on("excluir-servico", (data) => {
      if (data.flag) this.excluir(data.reg, data.flag);
    });
  },
  computed: {
    usuario () {
      return this.$store.state.auth.userData;
    },
  },
  methods: {
    trocaStatus (item) {
      eventHub.$emit("dialog-aguarde", true);

      let url = `/api/v1/admin/servicos`;
      window.axios.post(`${url}/${item.Id}`, { Finalizado: item.Finalizado })
        .then((res) => {
          if (!func.empty(res.data.error)) {
            func.notifica(res.data.message, "top", "red", 5000);
          } else {
            func.notifica("Serviço alterado com sucesso!!!", "top", "green");
          }
        })
        .catch((error) => {
          func.notifica(error, "top", "red", 5000);
        })
        .finally(() => {
          eventHub.$emit("dialog-aguarde", false);
        });
    },
    expandRow (e) {
      if (this.usuario.Tipo == 'A' && !this.mouseOnSwitch) {
        if (!func.empty(this.expanded)) {
          let found = this.expanded.filter((item) => item.Id == e.Id);
          if (!func.empty(found)) this.expanded.splice(0, 1);
          else this.expanded.splice(0, 1, e);
        } else this.expanded.push(e);
      }
    },
    pesquisar () {
      let data = func.jsonToQueryString(this.data);
      this.itensPesq = [];
      this.loading = true;

      let url = `api/v1/admin/servicos/pesquisar?${data}`;
      if (this.usuario.Tipo == 'U') url = `api/v1/admin/servicos/buscar?${data}`;
      window.axios.get(url)
        .then((res) => {
          if (res.data.error) {
            func.notifica("ERROR: " + res.data.message, "top", "red", 5000);
          } else {
            this.itensPesq = res.data.result;
          }
        })
        .catch((error) => {
          func.notifica(error, "top", "red", 5000);
        })
        .then(() => {
          this.loading = false;
        });
    },
    excluir (data, flag = false) {
      if (!flag) {
        let params = {};
        params.Open = true;
        params.Reg = data;
        params.Retorno = "excluir-servico";
        params.Titulo = "EXCLUIR";
        params.Texto = `Deseja realmente excluir o serviço <strong>"${data.Descricao}"</strong>?`;
        eventHub.$emit("dialog-excluir", params);
      } else {
        this.cliques++;
        if (this.cliques > 1) return;
        setTimeout(() => {
          this.cliques = 0;
        }, 1000);

        eventHub.$emit("dialog-aguarde", true);

        let url = `/api/v1/admin/servicos/${data.Id}/delete`;
        window.axios.post(url)
          .then((res) => {
            if (res.data.error) {
              func.notifica(`<strong>ERROR:</strong> ${res.data.message}`, "top", "red", 5000);
            } else {
              func.notifica("Serviço excluído com sucesso!!!", "top", "green");
              this.pesquisar();
            }
          })
          .catch((error) => {
            func.notifica(error, "top", "red", 5000);
          })
          .finally(() => {
            eventHub.$emit("dialog-aguarde", false);
          });
      }
    },
    editar (data) {
      this.idServico = data.Id;
      this.dlgServico = true;
    },
    limpaCampos () {
      this.itensPesq = [];
      this.$refs.form.reset();
      this.$refs.proposta.focus();
      setTimeout(() => {
        this.data = { finalizado: 0 };
      }, 100);
    },
    novoRegistro () {
      this.idServico = null;
      this.dlgServico = true;
    }
  },
};
</script>
