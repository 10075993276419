<template>
  <div>
    <v-toolbar absolute fixed dense style="left: 0; width: 100%;" color="primary">
      <v-row dense align="center">
        <v-col cols="10" class="white--text">
          <v-icon color="white" class="mr-2">mdi-account-hard-hat</v-icon>
          <span class="text-h5">{{ nmAcao }} serviço</span>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn icon color="white" @click="abreFechaJanela(false)">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <!-- FORMULÁRIO DE CADASTRO -->
    <v-form v-model="valid" ref="form" style="padding-top: 60px">
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            outlined dense autofocus
            :readonly="readOlny"
            hide-details="auto"
            ref="empresa"
            label="Empresa"
            v-model="data.IdCliente"
            :items="tblClientes"
            item-value="Id"
            item-text="Nome"
            :search-input.sync="searchClientes"
            :loading="clienteLoad"
            hide-no-data
            hide-selected
            :rules="validations.cli"
            prepend-inner-icon="mdi-office-building" />
        </v-col>

        <v-col cols="12" sm="2">
          <v-text-field
            outlined dense
            :readonly="readOlny"
            hide-details="auto"
            type="tel"
            class="numero"
            color="primary"
            label="Nº PROPOSTA"
            v-model="data.NumProposta"
            :rules="validations.prop"
            v-mask="'####-##'"
            prepend-inner-icon="mdi-eight-track" />
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            outlined dense
            :readonly="readOlny"
            hide-details="auto"
            type="tel"
            class="numero"
            color="primary"
            label="DIAS"
            v-model="data.Dias"
            :rules="validations.dias"
            v-mask="'###'"
            prepend-inner-icon="mdi-calendar" />
        </v-col>
        <v-col cols="12" sm="2">

          <v-menu
            v-model="mnuDate1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined dense
                :readonly="readOlny"
                type="tel"
                label="DT.ENTREGA"
                v-model="data.DtEntrega"
                :rules="validations.data"
                v-mask="'##/##/####'"
                v-bind="attrs"
                v-on="on"
                @focus="
                  datePicked = func.formataData(data.DtEntrega, 'y');
                dtCampo = 'DtEntrega'
                  "
                @input="datePicked = func.formataData(data.DtEntrega, 'y')" />
            </template>
            <v-date-picker
              no-title scrollable
              v-model="datePicked"
              min="1981-01-01">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            outlined dense
            :readonly="readOlny"
            hide-details="auto"
            type="tel"
            class="numero"
            color="primary"
            background-color="red lighten-4"
            label="ORD.COMPRA"
            v-model="data.OrdCompra"
            v-mask="'###########'" />
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            outlined dense
            :readonly="readOlny"
            hide-details="auto"
            class="numero"
            color="primary"
            label="IDENTFICAÇÃO"
            v-model="data.Identificacao"
            :rules="validations.placa"
            maxlength="8"
            @input="data.Identificacao = $event ? $event.toLocaleUpperCase() : ''"
            prepend-inner-icon="mdi-numeric" />
        </v-col>
        <v-col cols="12" sm="2">
          <v-autocomplete
            outlined dense
            :readonly="readOlny"
            hide-details="auto"
            label="NOTA"
            v-model="data.Nota"
            :items="func.statusNota"
            item-value="Id"
            item-text="Nome"
            :rules="validations.nota" />
        </v-col>

        <v-col cols="12" class="mt-5">
          <v-text-field
            outlined dense
            :readonly="readOlny"
            hide-details="auto"
            color="primary"
            label="DESCRIÇÃO"
            v-model="data.Descricao"
            :rules="validations.desc"
            prepend-inner-icon="mdi-text-box" />
        </v-col>

        <v-col cols="12" class="mt-5">
          <v-textarea
            outlined dense
            :readonly="readOlny"
            hide-details="auto"
            color="primary"
            label="OBSERVAÇÕES"
            v-model="data.Obs" />
        </v-col>

        <v-col v-if="readOlny" cols=" 12" class="text-center mt-3">
          <v-btn dark color="error" class="mx-1 mt-2" @click="abreFechaJanela(false)">
            <v-icon class="mr-2">mdi-door</v-icon>
            FECHAR
          </v-btn>
        </v-col>
        <v-col v-else cols="12" class="text-center mt-3">
          <v-btn color="success" class="mx-1 mt-2" :disabled="!valid" @click="submit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            SALVAR
          </v-btn>
          <v-btn dark color="error" class="mx-1 mt-2" @click="limpaCampos()">
            <v-icon class="mr-2">mdi-close</v-icon>
            LIMPAR
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-overlay v-model="carregando">
      <v-card color="primary" dark width="150">
        <v-card-text class="text-center">
          Aguarde...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-overlay>
  </div>
</template>
 
<script>
import func from "../../utils/funcoes";
import { mask } from "vue-the-mask";
import { eventHub } from "../../utils/eventHub";

export default {
  props: {
    idServico: {
      type: Number,
      default: null
    }
  },
  directives: { mask },
  data () {
    return {
      func,
      valid: false,
      carregando: false,
      data: {},
      acao: "ins",

      cliques: 0,
      readOlny: false,

      mnuDate1: false,
      datePicked: func.formataData(func.agora(), "y").substring(0, 10),
      dtCampo: null,

      tblClientes: [],
      clienteLoad: false,
      searchClientes: null,
      searchTimmer: null,

      validations: {
        cli: [(v) => !!v || "Empresa é obrigatório"],
        prop: [(v) => !!v || "Nº Proposta é obrigatório"],
        dias: [(v) => !!v || "Dias é obrigatório"],
        data: [
          (v) => !!v || "Dt.Entrega é obrigatório",
          (v) => (v && func.vldData(v)) || "Data inválida"
        ],
        placa: [
          (v) => !!v || "Identificação é obrigatório",
          (v) => (v && v.length > 4) || "Identificação inválida"
        ],
        nota: [(v) => !!v || "Nota é obrigatório"],
        desc: [(v) => !!v || "Descrição é obrigatório"],
      },
    };
  },
  async mounted () {
    if (this.idServico) this.pegaDados(this.idServico);
  },
  computed: {
    nmAcao () {
      return (this.usuario.Tipo == 'U' ? "Visualizar" : (this.acao == "ins" ? "Novo" : "Alterar"));
    },
    usuario () {
      return this.$store.state.auth.userData;
    },
  },
  watch: {
    datePicked () {
      this.dateFormat();
    },
    async searchClientes (texto) {
      if (func.empty(texto)) { this.tblClientes = []; return; }
      if (texto.length < 3 || this.clienteLoad) return;

      clearTimeout(this.searchTimmer);
      this.searchTimmer = setTimeout(async () => {
        this.tblClientes = await this.pegaClientes(texto);
      }, 500);
    },
    idServico (vlr) {
      let status = true;

      if (func.empty(vlr)) {
        this.limpaCampos();
        status = false;
      } else {
        this.pegaDados(vlr);
      }

      this.abreFechaJanela(status);
    }
  },
  methods: {
    dateFormat () {
      this.mnuDate1 = false;
      let dateFormatted = func.formataData(this.datePicked, "d");
      if (!func.empty(this.dtCampo)) this.data[this.dtCampo] = dateFormatted;
    },
    abreFechaJanela (data) {
      if (data == false) this.limpaCampos();
      eventHub.$emit("form-servico", data);
    },
    async pegaClientes (texto, id = false) {
      let url = `/api/v1/admin/clientes/pesquisar?Nome=${texto}`;
      if (id) url = `/api/v1/admin/clientes?id=${id}`;

      this.clienteLoad = true;

      return window.axios.get(url)
        .then((res) => {
          if (!func.empty(res.data.result.error)) {
            func.notifica(res.data.result.message, "top", "red", 5000);
            return [];
          } else {
            if (id) return [res.data.result];
            else return res.data.result;
          }
        })
        .catch((error) => {
          console.log("ERROR:", error);
          return [];
        }).finally(() => {
          this.clienteLoad = false;
        });
    },
    async pegaDados (id) {
      if (func.empty(id)) return;

      let url = `/api/v1/admin/servicos?id=${id}`;

      this.carregando = true;

      return window.axios.get(url)
        .then((res) => {
          if (res.data.result.error)
            func.notifica(res.data.result.message, "top", "red", 5000);
          else
            this.compoeCampos(res.data.result);

          return true;
        })
        .catch((error) => {
          console.log("ERROR:", error);
          return false;
        })
        .finally(() => {
          this.carregando = false;
        });
    },
    async compoeCampos (dados) {
      this.acao = "upd";

      if (this.usuario.Tipo == 'U') this.readOlny = true;

      this.tblClientes = await this.pegaClientes('', dados.IdCliente);
      this.data = JSON.parse(JSON.stringify(dados));

      delete this.data.Status;
    },
    submit () {
      this.cliques++;
      if (this.cliques > 1) return;
      setTimeout(() => {
        this.cliques = 0;
      }, 1000);

      let dados = JSON.parse(JSON.stringify(this.data));

      this.carregando = true;

      let url = `/api/v1/admin/servicos`;
      if (this.acao == "ins") {
        window.axios.post(url, dados)
          .then((res) => {
            if (!func.empty(res.data.error)) {
              func.notifica(res.data.message, "top", "red", 5000);
            } else {
              func.notifica("Serviço inserido com sucesso!!!", "top", "green");
              this.$emit('create', res.data);
              this.limpaCampos();
            }
          })
          .catch((error) => {
            func.notifica(error, "top", "red", 5000);
          })
          .finally(() => {
            this.carregando = false;
          });
      } else if (this.acao == "upd") {
        window.axios.post(`${url}/${dados.Id}`, dados)
          .then((res) => {
            if (!func.empty(res.data.error)) {
              func.notifica(res.data.message, "top", "red", 5000);
            } else {
              func.notifica("Serviço alterado com sucesso!!!", "top", "green");
              this.$emit('update', res.data);
              this.abreFechaJanela(false);
            }
          })
          .catch((error) => {
            func.notifica(error, "top", "red", 5000);
          })
          .finally(() => {
            this.carregando = false;
          });
      }
    },
    limpaCampos () {
      this.acao = "ins";
      this.$refs.form.reset();
      this.$refs.empresa.focus();
      this.data = {};
    },
  },
};
</script>
